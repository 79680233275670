import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'

import { Styled } from 'theme-ui'

import Layout from '../components/Layout'
import Container from '../components/Container'
import { PageHeader, FluidImage } from '../types/definitions'
import Card from '../components/Card'
import { css } from '@emotion/core'

type AboutPageProps = {
  data: {
    about: {
      frontmatter: {
        header: PageHeader
        footer: {
          showCTA: boolean
        }
        seo: { title: string; description: string }
      }
    }
    team: {
      nodes: {
        frontmatter: {
          title: string
          description: string
          roleLine: string
          picture: FluidImage
        }
      }[]
    }
    articles: {
      nodes: { fields: { slug: string }; frontmatter: { header: PageHeader } }[]
    }
  }
}

const AboutPage: FunctionComponent<AboutPageProps> = ({ data }) => {
  const { header, seo, footer } = data.about.frontmatter

  const team = data.team.nodes.map((node) => {
    return {
      name: node.frontmatter.title,
      roleLine: node.frontmatter.roleLine,
      description: node.frontmatter.description,
      image: node.frontmatter.picture,
    }
  })

  return (
    <Layout header={header} footer={footer} seo={seo}>
      <Container bg="white" extraSx={{ pt: [0, 0] }}>
        {team.map((person, idx) => (
          <Card
            key={idx}
            background={idx % 2 ? 'blue.1' : 'blue.0'}
            contentColor={idx % 2 ? 'blue.0' : 'blue.2'}
            image={person.image}
            imagePosDesktop={idx % 2 ? 'right' : 'left'}
            centerContent
          >
            <Styled.h2
              css={css`
                margin-bottom: 10px;
              `}
            >
              {person.name}
            </Styled.h2>
            <p>
              <strong>{person.roleLine}</strong>
            </p>
            <p>{person.description}</p>
          </Card>
        ))}
      </Container>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query About {
    about: mdx(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        footer {
          showCTA
        }
        ...header
        seo {
          description
          title
        }
      }
    }
    team: allMdx(
      filter: { frontmatter: { templateKey: { eq: "team-page" } } }
      sort: { order: ASC, fields: frontmatter___dateStarted }
    ) {
      nodes {
        frontmatter {
          title
          roleLine
          description
          picture {
            ...ImageFragment
          }
        }
      }
    }
  }
`
