import React, { FunctionComponent } from 'react'
import { Flex, Box } from 'rebass'
import { css } from '@emotion/core'
import Img, { FluidObject } from 'gatsby-image'

type CardProps = {
  background: string
  contentColor?: string
  centerContent?: boolean
  centerText?: boolean
  image?: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  imagePosDesktop?: 'left' | 'right' | 'top'
  itemWidth?: string | number
  boxShadow?: boolean
}

export const Card: FunctionComponent<CardProps> = ({
  background,
  contentColor = 'inherit',
  centerContent,
  centerText,
  image,
  imagePosDesktop,
  itemWidth,
  boxShadow,
  children,
  ...props
}) => {
  const isRow = imagePosDesktop && ['left', 'right'].includes(imagePosDesktop)

  const flexDirection = isRow
    ? imagePosDesktop === 'left'
      ? 'row'
      : 'row-reverse'
    : 'column'

  const width = itemWidth ? itemWidth : isRow ? '50%' : '100%'

  return (
    <Flex
      flexDirection={['column', flexDirection]}
      justifyContent={centerContent ? 'center' : 'left'}
      alignItems={centerContent ? 'center' : 'left'}
      sx={{
        bg: background,
        color: contentColor,
        boxShadow: boxShadow && '0px 3px 6px #00000029;',
        width: '100%',
        mt: [4, 0],
      }}
      {...props}
    >
      {image && (
        <Flex sx={{ width: ['100%', width] }}>
          <Img fluid={image.childImageSharp.fluid} />
        </Flex>
      )}
      <Flex
        flexDirection="column"
        sx={{ width: ['100%', width] }}
        css={css`
          text-align: ${centerText ? 'center' : 'left'};
          padding: 22px 31px;
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            margin-top: 0;
          }
          a,
          p {
            color: inherit;
            font-size: 14px;
            line-height: 26px;
            margin-top: 0;
          }
        `}
      >
        <Box>{children}</Box>
      </Flex>
    </Flex>
  )
}
